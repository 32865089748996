<template>
    <span>
        <img :src="kycSrc" :width="kycWidth" :height="kycHeight" class="img-fluid" :alt="kycLabel" />
    </span>
</template>

<script>
import {ref, inject, onMounted} from 'vue'
import Popper from 'vue3-popper'
import { useRoute } from 'vue-router'
import funcs from '@/functions/function'

export default {
    name: 'ImgBOKycEntity',
    components: { Popper },
    props: ['id', 'entity', 'width', 'height', 'label', ],
    setup (props) {
        const axios = inject('axios')
        const route = useRoute()
        const func = funcs

        const kycId = ref(props.id)
        const kycSrc = ref(null) 

        const kycWidth = ref(props.width)
        const kycHeight = ref(props.height)
        const kycLabel = ref(props.label)

        const buffer2base64 = (buffer) => {
            // handle large size image
            var binary = ''
            var bytes = new Uint8Array( buffer )
            var len = bytes.byteLength
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode( bytes[ i ] )
            }
            return window.btoa( binary )
        }

        onMounted(async () => {

            const p = {
                entityId: props.entity,
                kycId: kycId.value
            }
            console.info('- - - ImgBOKycEntity', kycId.value, p)
            axios.post( '/entity/su/entity/kyc', p, { responseType: 'arraybuffer' })
                .then((res) => {
                    const b64 = buffer2base64(res.data)
                    const imgData2 = 'data:' + res.headers['content-type'] + ';base64,' + b64
                    kycSrc.value = imgData2

                })
                .catch((error) => {
                    func.addLog('comp: imgBOKYCEntity', 'onMounted - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                })
        })

        return { 
            route, kycId, kycSrc, kycWidth, kycHeight, kycLabel
        }
    }
}
</script>

<style>

</style>