<template>
    <div>
        <component :is="kycObj" :id="route.params.kycId" :entity="route.params.entityId" />
    </div>
</template>

<script>
import { ref, inject, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import ImgBOKycEntity from '@/components/ImgBOKycEntity.vue'

export default {
    name: 'BOKYCEntity',
    components: { ImgBOKycEntity },
    setup () {
        const axios = inject('axios')
        const route = useRoute()

        const kycObj = computed(() => {
            return ImgBOKycEntity
        })

        return { 
            route, ImgBOKycEntity, kycObj
        }
    }
}
</script>

<style>

</style>